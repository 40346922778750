.main-container {
    grid-row-start: 2;
    width: 100%;
    display: grid;
    background-color: $darker-background;
    grid-template-columns: max-content;
    grid-template-rows: 1fr;
}

.ra-content {
    grid-column-start: 2;
    display: grid;
    padding: 1em;
}

.ra-card {
    background-color: $light-background;
    display: grid;
    grid-template-rows: 4em 1fr;
    overflow: hidden;
    height: 100%;

    .ra-card-header, .ra-card-footer {
        border: 1px solid $border-color;
        background-color: $darker-background;
        margin-bottom: 0;
    }

    .ra-card-header {
        border-bottom: none;

        .ra-card-header-title {
            i, img {
                margin: 0 .5em 0 .75em;
            }

            span {
                font-size: 1em;
                font-weight: bold;
            }
        }

        .buttons {
            span.button i {
                margin-right: .25em;
            }
        }
    }

    .ra-card-content {
        display: flex;
        flex-direction: column;
    }

    .ra-card-footer {
        grid-row-start: 3;
        border-top: none;
        font-size: .75em;
        padding: 0 1em;
    }
}
